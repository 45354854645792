<template>
  <NavBar :title="'Задача № ...'"
          :breadcrumbs="[{'url': { name: 'ContractListView', params: {} }, 'title': 'Договоры'}, {'url': { name: 'ContractDetailView', params: {id: 1} }, 'title': 'Договор № ...'}, {'url': { name: 'ContractTaskListView', params: {id: 1} }, 'title': 'Задачи'}]"></NavBar>

  <div class="container">

    <h3>Заголовок задачи</h3>

    <div>
      <!--      <button type="button" class="btn btn-secondary">Secondary</button>&nbsp;-->
      <button type="button" class="btn btn-success">Выполнена</button>&nbsp;
      <button type="button" class="btn btn-danger">Отменить</button>
    </div>

    <div class="mt-5">
      <h5>Детали задачи</h5>
      <div>Статус: <span class="badge bg-secondary">Сделать</span></div>
      <div>Исполнитель: Иванов Иван</div>
      <div>Автор: Петров Петр</div>
      <div>Создано: 01.01.2024</div>
      <div>Обновлено: 10.01.2024</div>
      <div>Запланировано времени: 15 ч</div>
      <div>Затрачено времени: 10 ч</div>
      <div>Остаток времени: 5 ч</div>
    </div>

    <div class="mt-5">
      <h5>Описание</h5>
      <p>Описание задачи ... Может быть длинным текстом...</p>
    </div>

    <!--    <ul class="nav nav-underline mb-3">-->
    <!--      <li class="nav-item">-->
    <!--        <a class="nav-link active" aria-current="page" href="#">Комментарии (0)</a>-->
    <!--      </li>-->
    <!--      <li class="nav-item">-->
    <!--        <a class="nav-link" href="#">Журнал работ (0)</a>-->
    <!--      </li>-->
    <!--      <li class="nav-item">-->
    <!--        <a class="nav-link" href="#">История изменений (0)</a>-->
    <!--      </li>-->
    <!--    </ul>-->

    <div class="mt-5">
      <HelpmateTabs>
        <HelpmateTab title="Комментарии (0)" active="true">
          <ckeditor
              v-model="editorData"
              :editor="editor"
              :config="editorConfig"
          />
          <button class="btn btn-primary mt-1" type="submit" @click.stop.prevent="submit()">Добавить</button>
        </HelpmateTab>
        <HelpmateTab title="Журнал работ (1)">
          <div>
            <p>Иванов Иван сделал запись о работе - 01.01.2024. Затраченное время: 5 ч</p>
          </div>
        </HelpmateTab>
        <HelpmateTab title="История изменений (1)">
          <div>
            <p>Иванов Иван создал задачу - 01.01.2024. <a href="#">Детали</a></p>
          </div>
        </HelpmateTab>
      </HelpmateTabs>
    </div>


  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import {ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo} from 'ckeditor5';
// import {Ckeditor} from '@ckeditor/ckeditor5-vue';
import HelpmateTab from "@/components/HelpmateTab.vue";
import HelpmateTabs from "@/components/HelpmateTabs.vue";

export default {
  name: 'TaskDetailView',
  components: {
    NavBar,
    // Ckeditor,
    HelpmateTab,
    HelpmateTabs
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        plugins: [Bold, Essentials, Italic, Mention, Paragraph, Undo],
        toolbar: ['undo', 'redo', '|', 'bold', 'italic'],
      }
    };
  }
};
</script>

<style>
.ck-editor__editable_inline {
  min-height: 100px;
}
</style>