<template>
  <NavBar :title="'Задачи по сотруднику - Иванов И.И.'" :breadcrumbs="[{'url': { name: 'UserListView', params: {} }, 'title': 'Сотрудники'}]"></NavBar>
  <div>

    <table class="table">
      <thead>
      <tr>
        <th>№</th>
        <th>Шифр</th>
        <th>Название</th>
        <th>Стоимость по договору, руб.</th>
        <th>Фактическая стоимость, руб.</th>
        <th>Стоимость трудозатрат, руб.</th>
        <th>Дата исполнителя</th>
        <th>Т план</th>
        <th>Т факт</th>
        <th>Примечание</th>
      </tr>
      </thead>
    </table>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: 'UserTaskListView',
  components: {NavBar},
  computed: {
    staffId() {
      return this.$route.params.id; // Получаем ID из параметров маршрута
    }
  },
};
</script>
