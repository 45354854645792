<template>
  <NavBar :title="'Договор № ...'" :breadcrumbs="[{'url': { name: 'ContractListView', params: {} }, 'title': 'Договоры'}]">

  </NavBar>
  <div>

    <RouterLink :to="{ name: 'ContractTaskListView', params: { id: 1 }}">Посмотреть задачи</RouterLink>

    <form>
      <div class="form-group">
        <label for="exampleInputEmail1">№ Договора</label>
        <input type="text" class="form-control" id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Шифр Договора (титула)</label>
        <input type="text" class="form-control" id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Наименование Договора (титула)</label>
        <input type="text" class="form-control" id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Стоимость Договора</label>
        <input type="text" class="form-control" id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Сумма доп. соглашений</label>
        <input type="text" class="form-control" disabled id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Итого по Договору с ДС</label>
        <input type="text" class="form-control" disabled id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Стоимость субподрядных работ</label>
        <input type="text" class="form-control" disabled id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Дополнительные расходы</label>
        <input type="text" class="form-control" disabled id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Наши деньги (с учетом ДС за вычетом суб. и доп. расх.)</label>
        <input type="text" class="form-control" disabled id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Фактические расходы</label>
        <input type="text" class="form-control" disabled id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Расчетная требуемая стоимость</label>
        <input type="text" class="form-control" disabled id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Стоимость заактированных работ</label>
        <input type="text" class="form-control" disabled id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Остаток по Договору</label>
        <input type="text" class="form-control" disabled id="exampleInputEmail1">
      </div>

      <button type="submit" class="btn btn-primary">Сохранить</button>
    </form>

    <h1>Доп. соглашения по договору</h1>

    <table class="table">
      <thead>
      <tr>
        <th>№ ДС</th>
        <th>Стоимость ДС, руб.</th>
        <th>Примечание</th>
        <th>Дата подписания</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td>1 000 000</td>
        <td>-</td>
        <td>11.09.2024</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th>Итого</th>
        <th>1 000 000</th>
        <td></td>
        <td></td>
      </tr>
      </tfoot>
    </table>

    <h1>Субподряды</h1>

    <table class="table">
      <thead>
      <tr>
        <th>№ суб.</th>
        <th>Наименование суб.</th>
        <th>Стоимость суб., руб.</th>
        <th>Примечание</th>
        <th>Дата подписания</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td>ООО "Тест"</td>
        <td>500 000</td>
        <td>-</td>
        <td>11.09.2024</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th>Итого</th>
        <th></th>
        <th>500 000</th>
        <td></td>
        <td></td>
      </tr>
      </tfoot>
    </table>

    <h1>Дополнительные расходы</h1>

    <table class="table">
      <thead>
      <tr>
        <th>№ доп. расх.</th>
        <th>Стоимость, руб.</th>
        <th>Примечание</th>
        <th>Дата</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td>10 000</td>
        <td>-</td>
        <td>11.09.2024</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th>Итого</th>
        <th>10 000</th>
        <td></td>
        <td></td>
      </tr>
      </tfoot>
    </table>

    <h1>Командировочные расходы</h1>

    <table class="table">
      <thead>
      <tr>
        <th>№</th>
        <th>Стоимость, руб.</th>
        <th>Примечание</th>
        <th>Дата</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td>10 000</td>
        <td>-</td>
        <td>11.09.2024</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th>Итого</th>
        <th>10 000</th>
        <td></td>
        <td></td>
      </tr>
      </tfoot>
    </table>

    <h1>Расчет фактической стоимости (трудозатраты с налогами, без накладных и прибыли)</h1>

    <table class="table">
      <thead>
      <tr>
        <th>№</th>
        <th>Наименование</th>
        <th>Стоимость, руб.</th>
        <th>Примечание</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td>Расходы на оплату труда:</td>
        <td>10 000</td>
        <td>-</td>
      </tr>
      <tr>
        <td>2</td>
        <td>Взносы в социальные фонды:</td>
        <td>10 000</td>
        <td>-</td>
      </tr>
      <tr>
        <td>3</td>
        <td>Страховые взносы на обязательное социальное страхование от несчастных случаев на производстве:</td>
        <td>10 000</td>
        <td>-</td>
      </tr>
      <tr>
        <td>4</td>
        <td>Командировочные расходы:</td>
        <td>10 000</td>
        <td>-</td>
      </tr>
      <tr>
        <td>5</td>
        <td>Итого по расходам:</td>
        <td>10 000</td>
        <td>-</td>
      </tr>
      <tr>
        <td>6</td>
        <td>Стоимость затрат с НДС:</td>
        <td>10 000</td>
        <td>-</td>
      </tr>
      </tbody>
    </table>

    <h1>Расчет требуемой стоимости (трудозатраты с налогами, накладными и прибылью)</h1>

    <table class="table">
      <thead>
      <tr>
        <th>№</th>
        <th>Наименование</th>
        <th>Стоимость, руб.</th>
        <th>Примечание</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td>Расходы на оплату труда:</td>
        <td>10 000</td>
        <td>-</td>
      </tr>
      <tr>
        <td>2</td>
        <td>Взносы в социальные фонды:</td>
        <td>10 000</td>
        <td>-</td>
      </tr>
      <tr>
        <td>3</td>
        <td>Страховые взносы на обязательное социальное страхование от несчастных случаев на производстве:</td>
        <td>10 000</td>
        <td>-</td>
      </tr>
      <tr>
        <td>4</td>
        <td>Командировочные расходы:</td>
        <td>10 000</td>
        <td>-</td>
      </tr>
      <tr>
        <td>5</td>
        <td>Накладные расходы:</td>
        <td>10 000</td>
        <td>-</td>
      </tr>
      <tr>
        <td>6</td>
        <td>Итого по расходам:</td>
        <td>10 000</td>
        <td>-</td>
      </tr>
      <tr>
        <td>7</td>
        <td>Стоимость с учетом прибыли:</td>
        <td>10 000</td>
        <td>-</td>
      </tr>
      <tr>
        <td>8</td>
        <td>Стоимость затрат с НДС:</td>
        <td>10 000</td>
        <td>-</td>
      </tr>
      </tbody>
    </table>

    <h1>Акты</h1>

    <table class="table">
      <thead>
      <tr>
        <th>№ акта</th>
        <th>Назначение акта</th>
        <th>Кол-во комплектов</th>
        <th>Стоимость вып-ных раб., вкл. НДС, руб.</th>
        <th>Зачет аванса от ст-ти вып. раб., вкл. НДС</th>
        <th>% аванса</th>
        <th>К оплате с НДС, руб.</th>
        <th>% от ст-ти вып. работ</th>
        <th>Удерж. от ст-ти вып. раб. с НДС, руб.</th>
        <th>% удержания</th>
        <th>Примечание</th>
        <th>Дата акта</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td></td>
        <td></td>
        <td>10 000</td>
        <td>= % аванса * ст. вып. раб., вкл. НДС</td>
        <td></td>
        <td>= ст. вып. раб., вкл. НДС – зачет аванса – удерж. от ст. вып. раб.</td>
        <td>= ст. вып. раб., вкл НДС / к оплате</td>
        <td>= ст. вып. раб., вкл. НДС * % удержания</td>
        <td></td>
        <td></td>
        <td>11.09.2024</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th>Итого</th>
        <th></th>
        <th></th>
        <th>10 000</th>
        <th>10 000</th>
        <th></th>
        <th>10 000</th>
        <th></th>
        <th>10 000</th>
        <th></th>
        <th></th>
      </tr>
      </tfoot>
    </table>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: 'ContractDetailView',
  components: {NavBar},
  computed: {
    contractId() {
      return this.$route.params.id;
    }
  },
};
</script>
