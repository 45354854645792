<template>
  <NavBar :title="'Новый договор'" :breadcrumbs="[{'url': { name: 'ContractListView', params: {} }, 'title': 'Договоры'}]"></NavBar>
  <div>

    <form>
      <div class="form-group">
        <label for="exampleInputEmail1">№ Договора</label>
        <input type="text" class="form-control" id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Шифр Договора (титула)</label>
        <input type="text" class="form-control" id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Наименование Договора (титула)</label>
        <input type="text" class="form-control" id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Стоимость Договора</label>
        <input type="text" class="form-control" id="exampleInputEmail1">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Ссылка на файл договора</label>
        <input type="text" class="form-control" id="exampleInputEmail1">
      </div>

      <button type="submit" class="btn btn-primary">Сохранить</button>
    </form>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: 'ContractCreateView',
  components: {NavBar},
};
</script>
