<template>

    <HelpmateTable :dataService="TaskDataService()" :columns="columns"></HelpmateTable>

</template>

<script>
import TaskDataService from '@/services/TaskDataService';
import HelpmateTable from "@/helpmate/components/HelpmateTable.vue";

export default {
  name: 'TaskListComponent',
  components: {
    HelpmateTable,
  },
  data() {
    return {
      columns: [
        {field: 'code', fieldType: 'text', title: 'Шифр'},
        {field: 'title', fieldType: 'text', title: 'Название'},
        {field: '', fieldType: 'priceint', title: 'Стоимость по договору, руб.', readonly: true, aggregate: 'sum'},
        {field: '', fieldType: 'priceint', title: 'Фактическая стоимость, руб.', readonly: true, aggregate: 'sum'},
        {field: '', fieldType: 'priceint', title: 'Стоимость трудозатрат, руб.', readonly: true, aggregate: 'sum'},
        {field: '', fieldType: 'datetime', title: 'Дата исполнителя', readonly: true},
        {field: '', fieldType: 'priceint', title: 'Т план', readonly: true, aggregate: 'sum'},
        {field: '', fieldType: 'priceint', title: 'Т факт', readonly: true, aggregate: 'sum'},
        {field: 'comment', fieldType: 'priceint', title: 'Примечание', readonly: true},
      ],
    }
  },
  methods: {
    TaskDataService() {
      return TaskDataService
    },
  },
};
</script>
