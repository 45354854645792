import moment from "moment/moment";

const pluralize = (count, singular, plural, genitive) => {
    const absCount = Math.abs(count) % 100; // Берем абсолютное значение для обработки
    const lastDigit = absCount % 10; // Последняя цифра

    if (absCount > 10 && absCount < 20) {
        return `${count} ${plural}`; // Для чисел от 11 до 19
    }

    if (lastDigit === 1) {
        return `${count} ${singular}`; // Для чисел, оканчивающихся на 1
    } else if (lastDigit >= 2 && lastDigit <= 4) {
        return `${count} ${plural}`; // Для чисел, оканчивающихся на 2, 3, 4
    } else {
        return `${count} ${genitive}`; // Для всех остальных случаев
    }
}

const formatDate = (value) => {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
    }
};

const formatDateTime = (value) => {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
    }
};

const formatTimeAgo = (date) => {
    const now = moment();
    const inputDate = moment(date);


    const duration = moment.duration(now.diff(inputDate));

    if (!duration.isValid()) {
        return 'Никогда';
    }

    if (duration.asSeconds() < 60) {
        return `${pluralize(Math.floor(duration.asSeconds()), 'секунда', 'секунды', 'секунд')} назад`;
    } else if (duration.asMinutes() < 60) {
        return `${pluralize(Math.floor(duration.asMinutes()), 'минута', 'минуты', 'минут')} назад`;
    } else if (duration.asHours() < 24) {
        return `${pluralize(Math.floor(duration.asHours()), 'час', 'часа', 'часов')} назад`;
    } else if (duration.asDays() < 30) {
        return `${pluralize(Math.floor(duration.asDays()), 'день', 'дня', 'дней')} назад`;
    } else if (duration.asMonths() < 12) {
        return `${pluralize(Math.floor(duration.asMonths()), 'месяц', 'месяца', 'месяцев')} назад`;
    } else {
        return `${pluralize(Math.floor(duration.asYears()), 'год', 'года', 'лет')} назад`;
    }
};

const formatNumberWithSpaces = (num, decimalPlaces = 0) => {
    if (num === null) {
        return "-";
    }
    // Преобразуем число в строку
    let numStr = num.toString();

    // Разделяем целую и дробную части (если есть)
    let [integerPart, decimalPart] = numStr.split('.');

    // Форматируем дробную часть, если указано количество знаков
    if (decimalPlaces > 0) {
        // Если дробная часть отсутствует, создаём её
        if (!decimalPart) {
            decimalPart = '0';
        }
        // Обрезаем или дополняем дробную часть до нужного количества знаков
        decimalPart = decimalPart.slice(0, decimalPlaces);
        while (decimalPart.length < decimalPlaces) {
            decimalPart += '0';
        }
    }

    // Используем массив для хранения частей
    let result = '';

    // Обрабатываем целую часть с конца
    for (let i = integerPart.length - 1, count = 0; i >= 0; i--, count++) {
        result = integerPart[i] + result; // Добавляем текущий символ в начало результата

        // Добавляем пробел после каждой третьей цифры, если это не последняя группа
        if (count % 3 === 2 && i !== 0) {
            result = ' ' + result;
        }
    }

    // Если есть дробная часть, добавляем её к результату
    if (decimalPlaces > 0) {
        result += '.' + decimalPart;
    }

    return result;
};

const formatPrice = (input) => {
    if (isNaN(input)) {
        return "-";
    }
    return formatNumberWithSpaces(input, 2);
};

const formatPriceInt = (input) => {
    if (isNaN(input)) {
        return "-";
    }
    return formatNumberWithSpaces(input, 0);
};

export {
    pluralize,
    formatDate,
    formatDateTime,
    formatTimeAgo,
    formatPrice,
    formatPriceInt,
    formatNumberWithSpaces,
}