import AbstractDataService from "@/helpmate/services/AbstractDataService";

class TaskDataService extends AbstractDataService {

    constructor() {
        super('/tasks/task');
    }

}

export default new TaskDataService();
