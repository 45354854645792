<template>
  <NavBar :title="'Задача'"></NavBar>

  <div class="container">

    <!--    <ul class="nav nav-underline mb-3">-->
    <!--      <li class="nav-item">-->
    <!--        <a class="nav-link active" aria-current="page" href="#">Комментарии (0)</a>-->
    <!--      </li>-->
    <!--      <li class="nav-item">-->
    <!--        <a class="nav-link" href="#">Журнал работ (0)</a>-->
    <!--      </li>-->
    <!--      <li class="nav-item">-->
    <!--        <a class="nav-link" href="#">История изменений (0)</a>-->
    <!--      </li>-->
    <!--    </ul>-->

    <HelpmateTabs>
      <HelpmateTab title="Комментарии (0)" active="true">
        <ckeditor
            v-model="editorData"
            :editor="editor"
            :config="editorConfig"
        />
        <button class="btn btn-primary mt-1" type="submit" @click.stop.prevent="submit()">Добавить</button>
      </HelpmateTab>
      <HelpmateTab title="Журнал работ (0)">1</HelpmateTab>
      <HelpmateTab title="История изменений (0)">2</HelpmateTab>
    </HelpmateTabs>


  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import {ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo} from 'ckeditor5';
// import {Ckeditor} from '@ckeditor/ckeditor5-vue';
import HelpmateTab from "@/components/HelpmateTab.vue";
import HelpmateTabs from "@/components/HelpmateTabs.vue";

export default {
  name: 'TaskDetailView',
  components: {
    NavBar,
    // Ckeditor,
    HelpmateTab,
    HelpmateTabs
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        plugins: [Bold, Essentials, Italic, Mention, Paragraph, Undo],
        toolbar: ['undo', 'redo', '|', 'bold', 'italic'],
      }
    };
  }
};
</script>

<style>
.ck-editor__editable_inline {
  min-height: 100px;
}
</style>