<template>
  <div class="d-flex justify-content-center" v-if="isLoading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div v-if="!isLoading">
    <table class="table">
      <thead>
      <tr>
        <th>№</th>
        <th>Шифр</th>
        <!--      <th>Контрагент</th>-->
        <th>Дата подписания</th>
        <th>Сумма, руб.</th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(obj, index) in objects"
          :key="index"
      >
        <td>{{ index + 1 }}</td>
        <td>{{ obj.title }}</td>
        <!--      <td>{{ obj.contractor }}</td>-->
        <td>{{ obj.date_at }}</td>
        <td>{{ Number(obj.amount) }}</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th>Итого</th>
        <th></th>
        <!--      <th></th>-->
        <th></th>
        <th>{{ totalAmount }}</th>
      </tr>
      </tfoot>
    </table>
  </div>

</template>

<script>
import ActDataService from '@/services/ActDataService';

export default {
  name: 'ActListComponent',
  components: {},
  computed: {
    totalAmount: function () {
      let sum = 0;
      this.objects.forEach(e => {
        sum += Number(e.amount);
      });
      return sum
    }
  },
  data() {
    return {
      isLoading: true,
      objects: [],
    };
  },
  methods: {
    getObjects() {
      ActDataService.getAll()
          .then(response => {
            this.objects = response.data.results;
            console.log(response.data);
            this.isLoading = false;
          })
          .catch(e => {
            console.log(e);
          });
    },
  },
  mounted() {
    this.getObjects();
  }
};
</script>
