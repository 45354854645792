<template>
  <NavBar :title="'Задачи'" :breadcrumbs="[{'url': { name: 'ContractListView', params: {} }, 'title': 'Договоры'}, {'url': { name: 'ContractDetailView', params: {id: 1} }, 'title': 'Договор № ...'}]"></NavBar>

    <h2>Шифр Договора:</h2>
    <h2>Наименование Договора:</h2>

    <table class="table cru-small-table">
      <thead>
      <tr>
        <th>№</th>
        <th>Шифр</th>
        <th>Название</th>
        <th>Стоимость по договору, руб.</th>
        <th>Фактическая стоимость, руб.</th>
        <th>Стоимость трудозатрат, руб.</th>
        <th>Дата выдачи</th>
        <th>Дата согласования</th>
        <th>Дата исполнителя</th>
        <th>Т план</th>
        <th>Т факт</th>
        <th>Исполнитель</th>
        <th>Согласование</th>
        <th>№ акта</th>
        <th>Примечание</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td><RouterLink :to="{ name: 'ContractTaskDetailView', params: { contractId: 1, taskId: 1 }}">Задача № ...</RouterLink></td>
        <td></td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th>Итого</th>
        <th></th>
      </tr>
      </tfoot>
    </table>

</template>

<script>

import NavBar from "@/components/NavBar.vue";

export default {
  name: 'ContractTaskListView',
  components: {NavBar},
};
</script>

<style>
  .cru-small-table {
    font-size: 10px;
  }
</style>