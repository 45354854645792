<template>

  <HelpmateSpinner :is-loading="isLoading"></HelpmateSpinner>

  <div v-if="!isLoading">

    <div class="spreadsheet table-responsive">
      <!-- создаем таблицу -->
      <table
          class="helpmate-table table table-hover table-bordered"
          ref="controlTable"
          tabindex="0"
          @keyup.space="editing ? null : toggleSelected()"
          @keydown.left="editing ? null : moveLeft()"
          @keydown.right="editing ? null : moveRight()"
          @keydown.up="editing ? null : moveUp()"
          @keydown.down="editing ? null : moveDown()"
          @keyup.shift.ctrl.enter="editing ? null : cloneCurrentRow()"
          @keyup.exact.ctrl.enter="editing ? null : addRow()"
          @keyup.exact.enter.prevent.stop="toggleEdit()"
          @keyup.exact.delete="editing ? null : deleteRows()"

      >
        <thead>
        <tr>
          <th style="width: 16px;"><input type="checkbox" v-model="selectAll" @change="toggleSelectAll"></th>
          <th style="width: 16px;">№</th>
          <th v-for="(column, columnIndex) in columns" :key="columnIndex" :class="{'text-muted': column.readonly}">
            {{ column.title }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(obj, rowIndex) in objects" :key="rowIndex" :class="{'selected-row': obj.selected}">
          <td><input type="checkbox" v-model="obj.selected"></td>
          <td>{{rowIndex+1}}</td>
          <td v-for="(cell, cellIndex) in obj.values" :key="cellIndex"
              @click.prevent.stop="selectCell(rowIndex, cellIndex)"
              @dblclick.prevent.stop="toggleEdit()"
              :class="{'selected-cell': cellIndex === selectedCellIndex[1] && rowIndex === selectedCellIndex[0]}">
            <input v-model="inputText" :id="`helpmate_cell_input_${rowIndex}_${cellIndex}`" type="text"
                   v-if="editing && cellIndex === selectedCellIndex[1] && rowIndex === selectedCellIndex[0]"
                   class="form-control"
            >
            <span v-else :class="cell.cssClasses" :title="cell.titleValue">{{ cell.formattedValue }}</span>
          </td>
        </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="2">Итого</th>
            <th v-for="(column, columnIndex) in footerColumns" :key="columnIndex">
              <span :class="column.cssClasses">{{ column.aggregatedValue }}</span>
            </th>
          </tr>
        </tfoot>
      </table>

      <div class="btn-group" role="group">
        <button @click="addRow()" class="btn btn-light">Добавить</button>
        <button @click="cloneCurrentRow()" class="btn btn-light">Клонировать</button>
        <button @click="deleteRows()" class="btn btn-danger">Удалить выделенные строки</button>
      </div>

      <div class="helpmate-table-help">
        Для перемещения по ячейкам и таблице используйте стрелки или курсор мыши.<br/>
        Для редактирования ячейки используйте Enter или двойной клик мыши.<br/>
        Для выделения строки используйте пробел или флажок слева.<br/>
        Для добавления новой строки используйте Ctrl+Enter или кнопку "Добавить".<br/>
        Для клонирования строки используйте Ctrl+Shift+Enter или кнопку "Клонировать".<br/>
        Для удаления строк используйте Delete или кнопку "Удалить выделенные строки". Удаляются выделенные строки.<br/>
      </div>
    </div>
  </div>
</template>

<script>
import HelpmateSpinner from "@/helpmate/components/HelpmateSpinner.vue";
import formatField from "./HelpmateTableCommon/formatField";

export default {
  name: 'HelpmateTable',
  // props: ['tableId'],
  props: ['dataService', 'columns'],
  components: [
    HelpmateSpinner
  ],
  data() {
    return {
      isLoading: true,
      footerColumns: [],
      // columns: [
      //   // {field: '', title: '№', readonly: true},
      //   {field: 'username', fieldType: 'text', title: 'Логин'},
      //   {field: 'last_name', fieldType: 'text', title: 'Фамилия'},
      //   {field: 'first_name', fieldType: 'text', title: 'Имя'},
      //   {field: 'staff_position_title', fieldType: 'text', title: 'Должность'},
      //   {field: 'date_joined', fieldType: 'datetime', title: 'Дата регистрации', readonly: true},
      //   {field: 'last_login', fieldType: 'timeago', title: 'Последняя активность', readonly: true},
      //   // {field: '', title: 'Задачи', readonly: true},
      //   {field: 'salary_monthly_amount', fieldType: 'priceint', title: 'Зарплата, руб./мес.', readonly: true},
      //   {
      //     field: 'salary_hourly_amount',
      //     fieldType: 'price',
      //     title: 'Стоимость часа в текущем месяце, руб.',
      //     readonly: true
      //   },
      // ],
      objects: [],
      // columns: [ // столбцы
      //   {title: '№', readonly: true},
      //   {title: 'Фамилия'},
      //   {title: 'Имя'},
      // ],
      // objects: [
      //   {selected: false, values: ['1', 'Иванов', 'Иван']},
      //   {selected: false, values: ['2', 'Петров', 'Петр']},
      //   {selected: false, values: ['3', 'Егоров', 'Егор']}
      // ],
      selectedCellIndex: [0, 0], // индекс выделенной ячейки
      selectAll: false,
      editing: false,
      inputText: '',
    }
  },
  methods: {
    moveLeft() {
      if (this.objects.length === 0) return;

      if (this.selectedCellIndex[1] > 0) {
        this.selectedCellIndex[1]--;
      }
    },
    moveRight() {
      if (this.objects.length === 0) return;

      const rowIndex = this.selectedCellIndex[0];
      const colIndex = this.selectedCellIndex[1];

      if (colIndex < this.objects[rowIndex].values.length - 1) {
        this.selectedCellIndex[1]++;
      }
    },
    moveUp() {
      if (this.objects.length === 0) return;

      const rowIndex = this.selectedCellIndex[0];

      if (rowIndex > 0) {
        this.selectedCellIndex[0]--;
      }
    },
    moveDown() {
      if (this.objects.length === 0) return;

      const rowIndex = this.selectedCellIndex[0];

      if (rowIndex < this.objects.length - 1) {
        this.selectedCellIndex[0]++;
      }
    },
    toggleSelected() {
      const rowIndex = this.selectedCellIndex[0];
      this.objects[rowIndex].selected = !this.objects[rowIndex].selected;
    },
    toggleEdit() {
      const rowIndex = this.selectedCellIndex[0];
      const colIndex = this.selectedCellIndex[1];

      if (this.columns[colIndex].readonly) {
        this.$store.commit('addToast', {
          title: "Изменение недоступно",
          message: "Данные в этом столбце доступны только для чтения."
        })
        return;
      }

      if (this.editing) {
        const value = this.inputText;
        const {
          formattedValue,
          titleValue,
          cssClasses,
        } = formatField(value, this.columns[colIndex].fieldType)
        // todo: refactor this
        this.saveCell(rowIndex, colIndex, value)

        this.objects[rowIndex].values[colIndex] = {
          value: value,
          formattedValue: formattedValue,
          titleValue: titleValue,
          cssClasses: cssClasses,
        }
      } else {
        this.inputText = this.objects[rowIndex].values[colIndex].value;
      }

      this.editing = !this.editing;

      if (this.editing) {
        const rowIndex = this.selectedCellIndex[0];
        const colIndex = this.selectedCellIndex[1];
        setTimeout(() => {
          document.getElementById(`helpmate_cell_input_${rowIndex}_${colIndex}`).focus()
        }, 1)
      } else {
        const controlTable = this.$refs.controlTable;
        if (controlTable) {
          controlTable.focus();
        }
      }
    },
    saveCell(rowIndex, cellIndex, newValue) {
      const id = this.objects[rowIndex].id
      const fieldName = this.columns[cellIndex].field
      let data = {}
      data[fieldName] = newValue
      this.dataService.patch(id, data)
          .then(response => {
            console.log(response)
          })
          .catch(e => {
            console.log(e);
          });
    },
    selectCell(rowIndex, cellIndex) {
      // если редактируем, то прекращаем
      if (this.editing) {
        this.toggleEdit()
      }
      this.selectedCellIndex = [rowIndex, cellIndex];
    },
    toggleSelectAll() {
      this.objects.forEach(item => {
        item.selected = this.selectAll;
      });
    },
    addRow() {
      // todo: add row
      this.dataService.createEmpty()
          .then(response => {
            console.log(response)
            const item = response.data
            //
            let itemArr = []
            this.columns.forEach(column => {
              const value = item[column.field]
              const {
                formattedValue,
                titleValue,
                cssClasses,
              } = formatField(value, column.fieldType)

              itemArr.push({
                value: value,
                formattedValue: formattedValue,
                titleValue: titleValue,
                cssClasses: cssClasses,
              })
            });

            this.objects.push({values: itemArr, selected: false, id: item['id']})

          })
          .catch(e => {
            console.log(e);
          });

      // this.objects.push(
      //     {selected: false, values: ['', '', '']},
      // )
    },
    cloneCurrentRow() {
      if (this.objects.length === 0) return;

      const rowIndex = this.selectedCellIndex[0];
      const id = this.objects[rowIndex].id

      this.dataService.createClone(id)
          .then(response => {
            console.log(response)
            const item = response.data
            //
            let itemArr = []
            this.columns.forEach(column => {
              const value = item[column.field]
              const {
                formattedValue,
                titleValue,
                cssClasses,
              } = formatField(value, column.fieldType)

              itemArr.push({
                value: value,
                formattedValue: formattedValue,
                titleValue: titleValue,
                cssClasses: cssClasses,
              })
            });

            this.objects.push({values: itemArr, selected: false, id: item['id']})

          })
          .catch(e => {
            console.log(e);
          });

      // this.objects.push(
      //     {selected: false, values: [...this.objects[rowIndex].values]},
      // )
    },
    deleteRows() {
      const countToDelete = this.objects.filter(obj => obj.selected === true).length
      if (countToDelete === 0) return;

      if (confirm('Вы уверены, что хотите удалить выделенные строки?')) {
        const rowIndex = this.selectedCellIndex[0];
        const cellIndex = this.selectedCellIndex[1];
        const selectedIds = this.objects.filter(obj => obj.selected === true).map(obj => obj.id)

        this.dataService.deleteList({ids: selectedIds})
            .then(response => {
              // console.log(response.deleted_count)
              const offset = response.data.deleted_count
              const newRowIndex = rowIndex - offset >= 0 ? rowIndex - offset : 0
              console.log('newRowIndex', newRowIndex, 'rowIndex', rowIndex, 'offset', offset)
              this.getAll([newRowIndex, cellIndex])
            })
            .catch(e => {
              console.log(e);
            });


      }

    },
    getAll(selectedCell = [0, 0]) {
      // get data
      this.dataService.getAll()
          .then(response => {
            this.objects = response.data.results.map(item => {
              let itemArr = []
              this.columns.forEach(column => {
                const value = item[column.field]
                const {
                  formattedValue,
                  titleValue,
                  cssClasses,
                } = formatField(value, column.fieldType)

                itemArr.push({
                  value: value,
                  formattedValue: formattedValue,
                  titleValue: titleValue,
                  cssClasses: cssClasses,
                })
              });

              return {values: itemArr, selected: false, id: item['id']};
            });
            // console.log(this.objects);
            this.isLoading = false;
            // focus
            setTimeout(() => {
              const controlTable = this.$refs.controlTable;
              if (controlTable) {
                controlTable.focus();
                this.selectCell(selectedCell[0], selectedCell[1])
              }
            }, 1)

          })
          .catch((e) => {
            console.log(e)
            this.objects = []
            this.isLoading = false;
            this.$store.commit('addToast', {
              title: "Ошибка при получении данных",
              message: `${e}`
            })
          });
    }
  },
  mounted() {
    this.getAll()
  },
  watch: {
    objects: {
      handler() {
        // selectAll
        this.selectAll = this.objects.every(item => item.selected);
        // aggregates
        this.footerColumns = this.columns.map(() => {return {
          aggregatedValue: '',
          cssClasses: '',
        }})
        this.columns.forEach((column, columnIndex) => {
          if (column.aggregate === 'sum') {
            let aggregatedValue = 0
            this.objects.forEach((row) => {
              aggregatedValue += Number(row.values[columnIndex].value)
            })
            const fValue = formatField(aggregatedValue, column.fieldType)
            this.footerColumns[columnIndex].aggregatedValue = fValue.formattedValue
            this.footerColumns[columnIndex].cssClasses = fValue.cssClasses
          }
        })
      },
      deep: true
    }
  }
}
</script>

<style>
.spreadsheet .selected-row td {
  /*background-color: #f8c140;*/
  background-color: var(--bs-warning); /* Использует переменную primary */
  color: white; /* Цвет текста */
}

.helpmate-table:focus .selected-cell {
  background-color: var(--bs-primary); /* изменяемый класс для выделенной ячейки */
  color: white; /* Цвет текста */
}

.helpmate-table-help {
  margin-top: 10px;
  font-size: 10px;
  line-height: 12px;
  color: #292929;
}

.helpmate-cell-number {
  text-align: right;
  font-family: "Lucida Console";
}

.helpmate-table thead tr th, .helpmate-table tfoot tr th {
  background: var(--bs-light);
}
</style>