<template>
  <NavBar :title="'Задачи'"></NavBar>

  <div class="container">
    <div>
      <RouterLink to="/tasks/create">Новая</RouterLink>
      <RouterLink to="/tasks/detail/1">Деталка</RouterLink>
    </div>

    <TaskListComponent></TaskListComponent>
  </div>

</template>

<script>

import NavBar from "@/components/NavBar.vue";
import TaskListComponent from "@/components/Tasks/Task/TaskListComponent.vue";

export default {
  name: 'TaskListView',
  components: {TaskListComponent, NavBar},
};
</script>
