import {formatDate, formatDateTime, formatPrice, formatPriceInt, formatTimeAgo} from "@/helpmate/utils";

const formatField = (value, fieldType = 'text') => {
    let formattedValue = ''
    let titleValue = ''
    let cssClasses = ''
    if (fieldType === 'text') {
        formattedValue = value
    } else if (fieldType === 'date') {
        formattedValue = formatDate(value)
    } else if (fieldType === 'datetime') {
        formattedValue = formatDateTime(value)
    } else if (fieldType === 'timeago') {
        formattedValue = formatTimeAgo(value)
        titleValue = formatDateTime(value)
    } else if (fieldType === 'price') {
        formattedValue = formatPrice(value)
        cssClasses = 'helpmate-cell-number'
    } else if (fieldType === 'priceint') {
        formattedValue = formatPriceInt(value)
        cssClasses = 'helpmate-cell-number'
    } else {
        formattedValue = '- Неизвестный тип поля -'
    }
    return {
        formattedValue,
        titleValue,
        cssClasses,
    }
}

export default formatField;