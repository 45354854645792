<template>

  <HelpmateTable :dataService="getDataService()" :columns="columns"></HelpmateTable>
<!--  <HelpmateSpinner :is-loading="isLoading"></HelpmateSpinner>-->

<!--  <div v-if="!isLoading">-->
<!--    <table class="table">-->
<!--      <thead>-->
<!--      <tr>-->
<!--        <th>№</th>-->
<!--        <th>Шифр</th>-->
<!--        <th>Руководитель проекта</th>-->
<!--        <th>Дата окончания</th>-->
<!--        <th>Остаток по договору, руб.</th>-->
<!--        <th>Задачи Договора</th>-->
<!--      </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--      <tr-->
<!--          v-for="(obj, index) in objects"-->
<!--          :key="index"-->
<!--      >-->
<!--        <td>{{ index + 1 }}</td>-->
<!--        <td><RouterLink :to="{ name: 'ContractDetailView', params: { id: obj.id }}">{{ obj.title }}</RouterLink></td>-->
<!--        <td>???</td>-->
<!--        <td>{{ obj.end_at }}</td>-->
<!--        <td>???</td>-->
<!--        <td><RouterLink :to="{ name: 'ContractTaskListView', params: { id: obj.id }}">Посмотреть задачи</RouterLink></td>-->
<!--      </tr>-->
<!--      </tbody>-->
<!--      <tfoot>-->
<!--      <tr>-->
<!--        <th>Итого</th>-->
<!--        <th></th>-->
<!--        <th></th>-->
<!--        <th></th>-->
<!--        <th>???</th>-->
<!--        <th></th>-->
<!--        <th></th>-->
<!--      </tr>-->
<!--      </tfoot>-->
<!--    </table>-->
<!--  </div>-->

</template>

<script>
import ContractDataService from '@/services/ContractDataService';
import HelpmateTable from "@/helpmate/components/HelpmateTable.vue";

export default {
  name: 'ContractListComponent',
  components: {
    HelpmateTable,
  },
  data() {
    return {
      columns: [
        {field: 'title', fieldType: 'text', title: 'Шифр'},
        {field: '', fieldType: 'text', title: 'Руководитель проекта', readonly: true},
        {field: 'end_at', fieldType: 'date', title: 'Дата окончания', readonly: true},
        {field: 'amount', fieldType: 'priceint', title: 'Остаток по договору, руб.', readonly: true, aggregate: 'sum'},
        {field: '', fieldType: 'priceint', title: 'Задачи Договора', readonly: true},
      ],
    }
  },
  methods: {
    getDataService() {
      return ContractDataService
    },
  },
};
</script>


<!--<script>-->
<!--import ContractDataService from '@/services/ContractDataService';-->
<!--import HelpmateSpinner from "@/helpmate/components/HelpmateSpinner.vue";-->
<!--import HelpmateTable from "@/helpmate/components/HelpmateTable.vue";-->

<!--export default {-->
<!--  name: 'ContractListComponent',-->
<!--  components: {HelpmateTable},-->
<!--  computed: {-->
<!--    totalAmount: function () {-->
<!--      let sum = 0;-->
<!--      this.objects.forEach(e => {-->
<!--        sum += Number(e.amount);-->
<!--      });-->
<!--      return sum-->
<!--    }-->
<!--  },-->
<!--  data() {-->
<!--    return {-->
<!--      isLoading: true,-->
<!--      objects: [],-->
<!--    };-->
<!--  },-->
<!--  methods: {-->
<!--    getObjects() {-->
<!--      ContractDataService.getAll()-->
<!--          .then(response => {-->
<!--            this.objects = response.data.results;-->
<!--            console.log(response.data);-->
<!--            this.isLoading = false;-->
<!--          })-->
<!--          .catch(e => {-->
<!--            console.log(e);-->
<!--          });-->
<!--    },-->
<!--  },-->
<!--  mounted() {-->
<!--    this.getObjects();-->
<!--  }-->
<!--};-->
<!--</script>-->
