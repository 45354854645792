import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index";
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/sidebars.css"
import "bootstrap"
import "bootstrap-icons/font/bootstrap-icons.css";
import 'ckeditor5/ckeditor5.css';
import store from './store';
import filters from './helpers/filters'

const app = createApp(App)
    .use(store)
    .use(router)
    .use(CkeditorPlugin)

app.config.globalProperties.$filters = filters
app.mount('#app')
