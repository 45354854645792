<template>
  <NavBar :title="'Поиск'"></NavBar>
<!--    <RouterLink to="/acts/">Новый</RouterLink>-->
</template>

<script>

import NavBar from "@/components/NavBar.vue";

export default {
  name: 'SearchView',
  components: {NavBar},
};
</script>
